import { ValidationError } from "@/types";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { accountMenuOnMessage } from "@hyperlocal/banking-utility";
import { create } from "zustand";
import {
  ToggleShowFieldPayload,
  UpdateFieldPayload,
} from "./update-password.types";
import { toggleFieldVisibility, updateField } from "./update-password.utils";

export type FormField = "oldPassword" | "confirmPassword" | "newPassword";

export type State = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  validationErrors: ValidationError[];
  showPassword: boolean;
  showConfirmPassword: boolean;
  showNewPassword: boolean;
  isSheetOpen: boolean;
  isUpdatePasswordOpen: boolean;
  isConfirmUpdatePasswordOpen: boolean;
};

type Action = {
  updateField: (props: { fieldName: FormField; fieldValue: string }) => void;
  toggleFieldVisibility: (props: { field: FormField }) => void;
  toggleSheetOpen: () => void;
  toggleUpdatePasswordOpen: () => void;
  toggleConfirmUpdatePasswordOpen: () => void;
  onUpdatePasswordSuccess: () => void;
};

const initialState: State = {
  oldPassword: "",
  confirmPassword: "",
  newPassword: "",
  showPassword: false,
  showConfirmPassword: false,
  showNewPassword: false,
  validationErrors: [
    {
      label: "Senha de 8 a 32 dígitos",
      status: "idle",
      field: "isLengthBetween8And32",
    },
    {
      label: "Contém letras e números",
      status: "idle",
      field: "containsLettersAndNumbers",
    },
    {
      label: "Contém letra maiúscula",
      status: "idle",
      field: "containsUpperCase",
    },
    {
      label: "Contém letra minúscula",
      status: "idle",
      field: "containsLowerCase",
    },
    {
      label: "Contém caractere especial",
      status: "idle",
      field: "containsSpecialChar",
    },
    {
      label: "Senhas iguais",
      status: "idle",
      field: "comparePasswords",
    },
  ],
  isSheetOpen: false,
  isUpdatePasswordOpen: false,
  isConfirmUpdatePasswordOpen: false,
};

export const usePasswordStore = create<State & Action>((set, get) => {
  accountMenuOnMessage(() => {
    set({ ...get(), isSheetOpen: !get().isSheetOpen });
  });
  return {
    ...initialState,
    updateField: ({
      fieldName,
      fieldValue,
    }: Pick<UpdateFieldPayload, "fieldName" | "fieldValue">) =>
      set(updateField({ fieldName, fieldValue, currentState: get() })),
    toggleFieldVisibility: ({ field }: Pick<ToggleShowFieldPayload, "field">) =>
      set(toggleFieldVisibility({ field, state: get() })),
    toggleSheetOpen: () => set({ ...get(), isSheetOpen: !get().isSheetOpen }),
    toggleUpdatePasswordOpen: () =>
      set({ ...get(), isUpdatePasswordOpen: !get().isUpdatePasswordOpen }),
    toggleConfirmUpdatePasswordOpen: () =>
      set({
        ...get(),
        isConfirmUpdatePasswordOpen: !get().isConfirmUpdatePasswordOpen,
      }),
    onUpdatePasswordSuccess: () => set(initialState),
  };
});
